import React from 'react';
import { LoadingIndicator, Typography } from 'ui';

const Loading = () => (
  <>
    <LoadingIndicator />
    <Typography variant="label-3">Verifying...</Typography>
  </>
);

export default Loading;
